import React from "react"
import PropTypes from "prop-types"

import ComputerDeskIcon from "./computer-desk-icon"
import LightBulbIcon from "./light-bulb-icon"
import CogIcon from "./cog-icon"
import OfficeIcon from "./office-icon"

import PeopleIcon from "./people-icon"
import CogPersonIcon from "./cog-person-icon"
import PeopleArrowIcon from "./people-arrow-icon"

import BrainIcon from "./brain-icon"
import AtomIcon from "./atom-icon"
import PaperShredIcon from "./paper-shred-icon"

import CircleDotsIcon from "./circle-dots-icon"
import PoundSignIcon from "./pound-sign-icon"
import InwardArrowsIcon from "./inward-arrows-icon"
import TrophyIcon from "./trophy-icon"
import CombinationIcon from "./combination-icon"



const AwardCategoryIcon = ({icon, size}) => {
  const style = {
    width: size,
    height: size,
    backgroundColor: `#ff9500`,
    borderRadius: `50%`
  }
  switch(icon) {
    case 'computer-desk':
      return <ComputerDeskIcon style={style} />
    case 'light-bulb':
      return <LightBulbIcon style={style} />
    case 'cog':
        return <CogIcon style={style} />
    case 'office':
        return <OfficeIcon style={style} />
    case 'people':
        return <PeopleIcon style={style} />
    case 'cog-person':
        return <CogPersonIcon style={style} />
    case 'people-arrow':
        return <PeopleArrowIcon style={style} />
    case 'brain':
        return <BrainIcon style={style} />
    case 'atom':
        return <AtomIcon style={style} />
    case 'paper-shred':
        return <PaperShredIcon style={style} />
    case 'circle-dots':
        return <CircleDotsIcon style={style} />
    case 'pound-sign':
        return <PoundSignIcon style={style} />
    case 'inward-arrows':
        return <InwardArrowsIcon style={style} />
    case 'trophy':
        return <TrophyIcon style={style} />
    case 'combination':
        return <CombinationIcon style={style} />
    default:
      return <div style={style}></div>
  }
}

AwardCategoryIcon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.string,
}

AwardCategoryIcon.defaultProps = {
    icon: `computer-desk`,
    size: `5rem`,
}

export default AwardCategoryIcon
