import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import CategoriesHeaderImage from "../components/categories-header"
import PlacesBackgroundImage from "../components/places-background-image"
import PeopleBackgroundImage from "../components/people-background-image"
import ProcessesBackgroundImage from "../components/processes-background-image"
import TechnologyBackgroundImage from "../components/technology-background-image"
import BandsCircle from "../components/bands-circle"
import BandsDiamond from "../components/bands-diamond"
import Row from "../components/bootstrap/row"
import AwardCategory from "../components/award-category"

const CategoriesPage = () => (
  <Background>
    <Layout>
      <SEO title="Criteria" />
      <Container>
        <PageTitle text="Criteria" />
        <CategoriesHeaderImage />
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-sm">
                <h4 style={{
                  fontWeight: `bold`,
                  padding: `1.5rem`
                }}>IN ADDITION TO THE CATEGORY-SPECIFIC CRITERIA, THE JUDGES WILL BE LOOKING FOR THE FOLLOWING FROM SUBMISSIONS:</h4>
                <ul>
                  <li style={{marginBottom: `1rem`}}>Innovation, Imagination and Ambition</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of a clear strategy for the project / policy, clearly communicated to staff and other stakeholders</li>
                  <li style={{marginBottom: `1rem`}}>Evidence of good management</li>
                  <li style={{marginBottom: `1rem`}}>Value for money</li>
                  <li style={{marginBottom: `1rem`}}>Supporting evidence that the project / policy has made a difference to services.</li>
                  <li>Evidence it has achieved or is well on the way to achieving what it set out to do</li>
                </ul>
              </div>
              <div className="col-sm">
                <div style={{
                  border: `1px solid #fff`,
                  padding: `2rem`,
                  paddingBottom: `1rem`
                }}>
                  <ul>
                    <li style={{marginBottom: `1rem`}}>If you are making different entries under several different categories, please indicate this and complete an entry for each. </li>
                    <li style={{marginBottom: `1rem`}}>Each application needs to specifically address the criteria for each individual category.</li>
                    <li style={{marginBottom: `1rem`}}>The same project can be entered in different categories but a separate submission must be sent for each one, geared towards the category it is entered into.</li>
                    <li style={{marginBottom: `1rem`}}>Public sector organisations  can still enter multiple submissions for the same category with different projects, should they so choose.</li>
                    <li style={{marginBottom: `1rem`}}>Entries are scored using the published criteria for each category in addition to the general criteria.</li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>

      <Container style={{marginTop: `5rem`}}>
        <PageTitle text="Categories" />
        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="places-tab" data-toggle="pill" href="#places" role="tab" aria-controls="places" aria-selected="true">Places</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="people-tab" data-toggle="pill" href="#people" role="tab" aria-controls="people" aria-selected="false">People</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="processes-tab" data-toggle="pill" href="#processes" role="tab" aria-controls="processes" aria-selected="false">Processes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="technology-tab" data-toggle="pill" href="#technology" role="tab" aria-controls="technology" aria-selected="false">Technology</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent" style={{ margin: `6rem 0`}}>
          <div className="tab-pane fade show active" id="places" role="tabpanel" aria-labelledby="places-tab">
            
            <Container>
              <div className="row" style={{
                position: `relative`
              }}>
                <div className="col-lg" style={{
                  background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                  padding: `3rem`
                }}>
                  <div style={{ margin: `0 1.1rem`}}>
                    <h4 style={{
                      display: `inline`,
                      backgroundColor: `#d23464`,
                      padding: `.5rem 0`,
                      lineHeight: `3.4rem`,
                      boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                      textTransform: `uppercase`,
                      color: `#fff`,
                      fontFamily: `Avenir`,
                      fontWeight: `900`
                    }}>Places<br /><small>(The Workspace &amp; The Workplace)</small></h4>
                  </div>
                  <p style={{
                    color: `#fff`,
                    fontFamily: `Din Pro`,
                    marginTop: `1.5rem`
                  }}>The expectation is that entries will reflect the government’s strategy &amp; guidelines on reducing and making more effective &amp; sustainable public property portfolios, whilst improving the working environment, providing great places to work and better public services. We want to witness innovation, imagination and ambition with evidence of a clear strategy for the project / policy, clearly communicated to staff and other stakeholders which also focuses on bringing the workforce together as a community. Evidence of good management, value for money and supporting evidence that the project / policy has made a difference, or that it has achieved or is well on the way to achieving what it set out to do.</p>
                </div>
                <PlacesBackgroundImage className="col-lg">
                  <div style={{
                    background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                    width: `10rem`,
                    height: `10rem`,
                    borderRadius: `50%`,
                    position: `absolute`,
                    bottom: `1.5rem`,
                    right: `-5rem`
                  }} />
                  <BandsCircle style={{ position: `absolute`, top: `50%`, right: `-16rem`, marginTop: `-9rem`}} size="20rem" />
                  <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
                </PlacesBackgroundImage>
              </div>
            </Container>

            <Container style={{margin: `5rem 0`}}>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Workplace &amp; Workspace Transformation" gridColor="#d23464" icon="computer-desk">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Design</li>
                      <li>Space Management</li>
                      <li>Flexibility</li>
                      <li>Choice</li>
                      <li>Variety</li>
                      <li>Storage</li>
                      <li>Meeting Rooms</li>
                      <li>Customer-Facing Areas</li>
                      <li>Use of Technology</li>
                      <li>Ambiance and Security</li>
                      <li>Community Initiatives</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Asset Management Innovation" gridColor="#30c4bf" icon="cog">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Expertise</li>
                      <li>Facilities Management</li>
                      <li>Sustainability</li>
                      <li>Technology</li>
                      <li>Energy and Efficiency</li>
                      <li>Improving Working Environments</li>
                      <li>Use of Technology</li>
                      <li>Ambiance and Security</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Efficiency in The Property Portfolio" gridColor="#30c4bf" icon="office">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Location &amp; Re-Location</li>
                      <li>Shared Services</li>
                      <li>Flexibility</li>
                      <li>Surplus and Disposal</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Overcoming Resistance to Change</li>
                      <li>Improving Effectiveness and Outcomes</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
            </Container>

          </div>
          <div className="tab-pane fade" id="people" role="tabpanel" aria-labelledby="people-tab">
            
            <Container>
              <div style={{ position: `relative`}}>
                <BandsCircle style={{ position: `absolute`, bottom: `-6rem`, right: `-14rem`, zIndex: `1`}} size="20rem" />    
                <BandsDiamond type="fine" style={{ position: `absolute`, left: `-2.5rem`, top: `-2.5rem`}} />
                <div style={{
                  background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 100%)`,
                  width: `10rem`,
                  height: `10rem`,
                  borderRadius: `50%`,
                  position: `absolute`,
                  bottom: `7rem`,
                  right: `-15rem`,
                  zIndex: `-1`
                }} />
                <div className="row">
                  <div className="col-md" style={{
                    background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                    padding: `3rem`,
                    position: `relative`
                  }}>
                    <h4 style={{
                      display: `inline`,
                      backgroundColor: `#d23464`,
                      padding: `.5rem 0`,
                      lineHeight: `3.4rem`,
                      boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                      margin: `1.1rem`,
                      textTransform: `uppercase`,
                      color: `#fff`,
                      fontFamily: `Avenir`,
                      fontWeight: `900`
                    }}>People</h4>
                    <p style={{
                      color: `#fff`,
                      fontFamily: `Din Pro`,
                      marginTop: `1.5rem`
                    }}>Entries need to demonstrate an organisation’s commitment to solid leadership. We want to witness action and results, not just words and presenteeism. Where appropriate, staff have the freedom to choose where and when to work whilst meeting business needs. Protocols and behaviours; new smarter working practices should be evaluated for effectiveness on staff wellbeing and on service delivery. The best entries will demonstrate communication and engagement; there are clear mechanisms for recognition and regularly communicating smarter working practice across the organisation and beyond. We want to hear from individuals / teams who have driven smarter working programmes and who are empowering and motivating staff to change working practices. There must be tangible proof of improvements to internal effectiveness and service delivery.</p>
                  </div>
                  <PeopleBackgroundImage className="col-md" />
                </div>
              </div>
            </Container>

            <Container style={{margin: `5rem 0`}}>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Corporate Leadership, Management &amp; Culture" gridColor="#d23464" icon="people">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Overcoming Cultural Resistance to Change</li>
                      <li>Ownership and Accountability</li>
                      <li>Commitment</li>
                      <li>Collaboration</li>
                      <li>Impact Felt Across the Organisation</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Skills &amp; Succession Planning" gridColor="#30c4bf" icon="cog-person">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Equipping Staff with Necessary Skills</li>
                      <li>New Training Techniques and Successful Strategies</li>
                      <li>Informed Decision Making</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Workforce Management" gridColor="#30c4bf" icon="people-arrow">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Changes to your Systems</li>
                      <li>Accommodating All Worker Types</li>
                      <li>Improvements in Performance</li>
                      <li>Stronger Staff Engagement</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>The Impact on New Solutions on Staff</li>
                      <li>The Impact on Service Delivery</li>
                      <li>The Impact on the Customer Experience</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
            </Container>
          </div>
          <div className="tab-pane fade" id="processes" role="tabpanel" aria-labelledby="processes-tab">
            
            <Container>
              <div style={{ position: `relative`}}>
              <BandsCircle style={{ position: `absolute`, left: `-10rem`, top: `-8rem`}} size="20rem" />
              <BandsDiamond type="fine" style={{ position: `absolute`, left: `60%`, bottom: `-2.5rem`}} />
              <div style={{
                background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                width: `7rem`,
                height: `7rem`,
                borderRadius: `50%`,
                position: `absolute`,
                top: `-3rem`,
                right: `8%`,
                zIndex: `1`
              }} />
              <div className="row">
                <div className="col-md" style={{
                  background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                  padding: `3rem`
                }}>
                  <h4 style={{
                    display: `inline`,
                    backgroundColor: `#d23464`,
                    padding: `.5rem 0`,
                    lineHeight: `3.4rem`,
                    boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                    margin: `1.1rem`,
                    textTransform: `uppercase`,
                    color: `#fff`,
                    fontFamily: `Avenir`,
                    fontWeight: `900`
                  }}>Processes</h4>
                  <p style={{
                    color: `#fff`,
                    fontFamily: `Din Pro`,
                    marginTop: `1.5rem`
                  }}>We will need to witness real change to existing workflows, where work is being completed with greater efficiency, at faster speed and at lower costs. Entries need to demonstrate that effective and carefully considered strategies were made prior to change. We expect entries to demonstrate clear evidence that changes and digitalisation to existing processes have been subject to rigorous evaluation before being implemented and that both the User and Customer Experience have been equally considered.</p>
                </div>
                <ProcessesBackgroundImage className="col-md" />
              </div>
              </div>
            </Container>

            <Container style={{margin: `5rem 0`}}>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Automation (Digital / Robotic / AI)" gridColor="#d23464" icon="brain">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>The Increasing Role of Automation in Routine Tasks</li>
                      <li>How Automation Improves Engagement</li>
                      <li>Efficiency Savings</li>
                      <li>Reallocation of Resources and Improvements to Service Delivery</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Data &amp; Interoperability" gridColor="#30c4bf" icon="atom">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>How Gaps Between Silos Have Been Bridged</li>
                      <li>How Disparate Data &amp; IT Systems Have Been Integrated</li>
                      <li>Delivering Better and More Joined-up Services</li>
                      <li>A Robust Security Strategy for Data Protection</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Paperless" gridColor="#30c4bf" icon="paper-shred">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>How you have Eliminated the Print Key</li>
                      <li>Changed the Culture of Printing</li>
                      <li>Redesigned Processes &amp; Workflows for Zero Paper</li>
                      <li>Increased Operational Efficiency</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Analytics</li>
                      <li>Improvements to Services</li>
                      <li>Effectiveness</li>
                      <li>Savings</li>
                      <li>Planning and Experience</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
            </Container>

          </div>
          <div className="tab-pane fade" id="technology" role="tabpanel" aria-labelledby="technology-tab">
            
            <Container>
              <div style={{
                position: `relative`
              }}>
                <BandsCircle style={{ position: `absolute`, bottom: `-5rem`, right: `-10rem`, zIndex: `2`}} size="20rem" />    
                <div style={{
                  background: `linear-gradient(90deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 100%)`,
                  width: `8rem`,
                  height: `8rem`,
                  borderRadius: `50%`,
                  position: `absolute`,
                  bottom: `-5rem`,
                  right: `5rem`,
                  zIndex: `1`
                }} />
                <div className="row">
                  <div className="col-md" style={{
                    background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
                    padding: `3rem`
                  }}>
                    <h4 style={{
                      display: `inline`,
                      backgroundColor: `#d23464`,
                      padding: `.5rem 0`,
                      lineHeight: `3.4rem`,
                      boxShadow: `1.2rem 0 0 #d23464, -1.2rem 0 0 #d23464`,
                      margin: `1.1rem`,
                      textTransform: `uppercase`,
                      color: `#fff`,
                      fontFamily: `Avenir`,
                      fontWeight: `900`
                    }}>Technology</h4>
                    <p style={{
                      color: `#fff`,
                      fontFamily: `Din Pro`,
                      marginTop: `1.5rem`
                    }}>A wide and rapidly changing field, we are looking both for the steady improvements that technology can bring but also for the new disruptive innovations. All must provide clear evidence of improving internal operations, customer delivery and experience, as well as offering sustainable cost savings. Smarter Working & Paperless Operations cannot be realised without technology, but we want to hear from entries where innovation has been closely guided by government policy.</p>
                  </div>
                  <TechnologyBackgroundImage className="col-md" />
                </div>
              </div>
            </Container>

            <Container style={{margin: `5rem 0`}}>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Unified Communications" gridColor="#d23464" icon="circle-dots">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>The Changing Methods of Communications</li>
                      <li>The Role of Cloud</li>
                      <li>Omni-channel Customer Services</li>
                      <li>Efficiency Savings vs Traditional Methods</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Efficiency Savings" gridColor="#30c4bf" icon="pound-sign">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Less Costs</li>
                      <li>Less Waste</li>
                      <li>Reallocation of Resources</li>
                      <li>Savings (not at the Expense of Service Delivery &amp; Customer Experience)</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
              <Row>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Small Scale Project" gridColor="#30c4bf" icon="inward-arrows">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>In-House Development</li>
                      <li>Smart Procurement</li>
                      <li>Costs vs Service Benefits</li>
                    </ul>
                  </AwardCategory>
                </div>
                <div className="col-md p-lg-4">
                  <AwardCategory title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <strong>Nominations for this category should consider:</strong>
                    <ul>
                      <li>Needs-Driven Design</li>
                      <li>Choice of Technology</li>
                      <li>Superior Service</li>
                      <li>Multi-channel Options including Apps,</li>
                      <li>Good Accessibility</li>
                      <li>Inclusive for All Users</li>
                    </ul>
                  </AwardCategory>
                </div>
              </Row>
            </Container>

          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
    </Layout>
  </Background>
)

export default CategoriesPage
